//
// Responsive: Utility classes
// TODO: Grab this from BS 4! as we now use the media queries from BS 4 also.
// --------------------------------------------------

@mixin responsive-visibility($parent) {
  #{$parent} {
    display: block !important;
  }
  table#{$parent}  { display: table !important; }
  tr#{$parent}     { display: table-row !important; }
  th#{$parent},
  td#{$parent}     { display: table-cell !important; }
}

// [converter] $parent hack
@mixin responsive-invisibility($parent) {
  #{$parent} {
    display: none !important;
  }
}


// IE10 in Windows (Phone) 8
//
// Support for responsive views via media queries is kind of borked in IE10, for
// Surface/desktop in split view and for Windows Phone 8. This particular fix
// must be accompanied by a snippet of JavaScript to sniff the user agent and
// apply some conditional CSS to *only* the Surface/desktop Windows 8. Look at
// our Getting Started page for more information on this bug.
//
// For more information, see the following:
//
// Issue: https://github.com/twbs/bootstrap/issues/10497
// Docs: http://getbootstrap.com/getting-started/#support-ie10-width
// Source: http://timkadlec.com/2013/01/windows-phone-8-and-device-width/
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/

@at-root {
  @-ms-viewport {
    width: device-width;
  }
}


// Visibility utilities
// Note: Deprecated .visible-xs, .visible-sm, .visible-md, and .visible-lg as of v3.2.0

@include responsive-invisibility('.visible-xs');
@include responsive-invisibility('.visible-sm');
@include responsive-invisibility('.visible-md');
@include responsive-invisibility('.visible-lg');
@include responsive-invisibility('.visible-xlg');
@include responsive-invisibility('.visible-xxlg');

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-xlg-block,
.visible-xlg-inline,
.visible-xlg-inline-block,
.visible-xxlg-block,
.visible-xxlg-inline,
.visible-xxlg-inline-block{
  display: none !important;
}

@media (max-width: $small-max-breakpoint) {
  @include responsive-visibility('.visible-xs');
}
.visible-xs-block {
  @media (max-width: $small-max-breakpoint) {
    display: block !important;
  }
}
.visible-xs-inline {
  @media (max-width: $small-max-breakpoint) {
    display: inline !important;
  }
}
.visible-xs-inline-block {
  @media (max-width: $small-max-breakpoint) {
    display: inline-block !important;
  }
}

@media (min-width: $small-min-breakpoint) and (max-width: $small-max-breakpoint) {
  @include responsive-visibility('.visible-sm');
}
.visible-sm-block {
  @media (min-width: $small-min-breakpoint) and (max-width: $small-max-breakpoint) {
    display: block !important;
  }
}
.visible-sm-inline {
  @media (min-width: $small-min-breakpoint) and (max-width: $small-max-breakpoint) {
    display: inline !important;
  }
}
.visible-sm-inline-block {
  @media (min-width: $small-min-breakpoint) and (max-width: $small-max-breakpoint) {
    display: inline-block !important;
  }
}

@media (min-width: $medium-min-breakpoint) and (max-width: $medium-max-breakpoint) {
  @include responsive-visibility('.visible-md');
}
.visible-md-block {
  @media (min-width: $medium-min-breakpoint) and (max-width: $medium-max-breakpoint) {
    display: block !important;
  }
}
.visible-md-inline {
  @media (min-width: $medium-min-breakpoint) and (max-width: $medium-max-breakpoint) {
    display: inline !important;
  }
}
.visible-md-inline-block {
  @media (min-width: $medium-min-breakpoint) and (max-width: $medium-max-breakpoint) {
    display: inline-block !important;
  }
}

@media (min-width: $large-min-breakpoint) {
  @include responsive-visibility('.visible-lg');
}
.visible-lg-block {
  @media (min-width: $large-min-breakpoint) {
    display: block !important;
  }
}
.visible-lg-inline {
  @media (min-width: $large-min-breakpoint) {
    display: inline !important;
  }
}
.visible-lg-inline-block {
  @media (min-width: $large-min-breakpoint) {
    display: inline-block !important;
  }
}

@media (min-width: $xlarge-min-breakpoint) {
  @include responsive-visibility('.visible-xlg');
}
.visible-xlg-block {
  @media (min-width: $xlarge-min-breakpoint) {
    display: block !important;
  }
}
.visible-xlg-inline {
  @media (min-width: $xlarge-min-breakpoint) {
    display: inline !important;
  }
}
.visible-xlg-inline-block {
  @media (min-width: $xlarge-min-breakpoint) {
    display: inline-block !important;
  }
}

@media (min-width: $xxlarge-min-breakpoint) {
  @include responsive-visibility('.visible-xxlg');
}
.visible-xxlg-block {
  @media (min-width: $xxlarge-min-breakpoint) {
    display: block !important;
  }
}
.visible-xxlg-inline {
  @media (min-width: $xxlarge-min-breakpoint) {
    display: inline !important;
  }
}
.visible-xxlg-inline-block {
  @media (min-width: $xxlarge-min-breakpoint) {
    display: inline-block !important;
  }
}


@media (max-width: $small-max-breakpoint) {
  @include responsive-invisibility('.hidden-xs');
}

@media (min-width: $small-min-breakpoint) and (max-width: $small-max-breakpoint) {
  @include responsive-invisibility('.hidden-sm');
}

@media (min-width: $medium-min-breakpoint) and (max-width: $medium-max-breakpoint) {
  @include responsive-invisibility('.hidden-md');
}

@media (min-width: $large-min-breakpoint) and (max-width: $large-max-breakpoint) {
  @include responsive-invisibility('.hidden-lg');
}

@media (min-width: $xlarge-min-breakpoint) and (max-width: $xlarge-max-breakpoint) {
  @include responsive-invisibility('.hidden-xlg');
}

@media (min-width: $xxlarge-min-breakpoint) {
  @include responsive-invisibility('.hidden-xxlg');
}


// Print utilities
//
// Media queries are placed on the inside to be mixin-friendly.

// Note: Deprecated .visible-print as of v3.2.0

@include responsive-invisibility('.visible-print');

@media print {
  @include responsive-visibility('.visible-print');
}
.visible-print-block {
  display: none !important;

  @media print {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;

  @media print {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;

  @media print {
    display: inline-block !important;
  }
}

@media print {
  @include responsive-invisibility('.hidden-print');
}
